const TWITTER_HANDLE = '@PuppyCoinPUP'

const WEBSITE = 'https://puppycoin.fun'

const HASHTAG = `#PuppyCoin`

const PHRASE_1 = [
    'Getting in on the ground floor for',
    'Next big thing is this fair launch of',
    'Pumped for',
    'Next big meme coin just launched',
    'Huge potential with this fair launch',
    'So pumped for',
    'Here we go',
    'Send it',
    'Moon soon',
    'This will go viral',
    'Lets make this go viral',
    'What if I told you the next #Shiba just launched?',
    'Next #Shiba just launched',
    'The next #Shiba just launched',
    `Move over #Shiba, there's a new dog in town`,
    `We're coming for #Shiba! New dog is`,
    `If you missed #Shiba, don't miss the puppies`,
    `The next #Shiba is here`,
    `Getting in on the ground floor of the next #Shiba with`,
    `Unbelievably cheap, the next #Shiba is`,
]

function chooseRandom(list) {
    const index = Math.floor(list.length * Math.random())
    return list[index]
}

export function createTweetText(address) {
    return `${chooseRandom(PHRASE_1)} ${HASHTAG}
${TWITTER_HANDLE} 🐶 🚀 🌙
${WEBSITE}`
}