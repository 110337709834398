export const PRICE_ETH_STR = '1'

export const WEI_PER_ETH_STR = '1000000000000000000'

const TESTNET_CONTRACT = '0x183B665119F1289dFD446a2ebA29f858eE0D3224'
const MAINNET_CONTRACT = '0x2696Fc1896F2D5F3DEAA2D91338B1D2E5f4E1D44'
const MAINNET = true

export const CONTRACT_ADDRESS = MAINNET ? MAINNET_CONTRACT : TESTNET_CONTRACT 

export const ETHERSCAN_BASE_URL = `https://${MAINNET ? '' : 'rinkeby.'}etherscan.io`

export const PUPPY_LOGO_URL = `https://puppycoin.fun/puppy-logo.png`

export const HOW_TO_IMPORT_URL = `https://metamask.zendesk.com/hc/en-us/articles/360015489031-How-to-add-unlisted-tokens-custom-tokens-in-MetaMask`

export const UNISWAP_TRADE_URL = `https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x2696Fc1896F2D5F3DEAA2D91338B1D2E5f4E1D44`