import './App.css';
import { CONTRACT_ADDRESS, ETHERSCAN_BASE_URL, PUPPY_LOGO_URL, HOW_TO_IMPORT_URL, UNISWAP_TRADE_URL } from './constants'
import { useEffect, useState } from 'react';
import { createTweetText } from './tweets'
import Web3Modal from "web3modal";
import { providerOptions } from './connectors'
import { providers } from 'ethers';
import {
  ChainId,
  ETH,
} from 'uniswap-dapp-integration-shared';
import UniswapReact from 'uniswap-react';
import { UniswapVersion } from 'simple-uniswap-sdk';

const web3Modal = new Web3Modal({
  //network: "homestead", // optional
  cacheProvider: true, // optional
  providerOptions // required
});

let provider, myAddress

function ellipseAddress(address = '', width = 7) {
  if (!address) {
    return '';
  }
  return `${address.slice(0, width + 1)}...${address.slice(-width)}`;
}

function App() {
  const [walletConnected, setWalletConnected] = useState(false)
  const [address, setAddress] = useState(null)
  const [myWeb3Provider, setMyWeb3Provider] = useState(null)
  const [uniswapDappSharedLogicContext, setUniswapDappSharedLogicContext] = useState(undefined)

  useEffect(() => {
    (async () => {
      console.log(address)
      const uniswapDappSharedLogicContext = {
        supportedNetworkTokens: [
          {
            chainId: ChainId.MAINNET,
            defaultInputToken: ETH.MAINNET().contractAddress,
            defaultOutputToken: '0x2696Fc1896F2D5F3DEAA2D91338B1D2E5f4E1D44',
            supportedTokens: [
              { contractAddress: ETH.MAINNET().contractAddress },
              { 
                contractAddress: '0x2696Fc1896F2D5F3DEAA2D91338B1D2E5f4E1D44',
                tokenImageContext: {
                  image: 'puppy-logo.png'
                }
              }
            ],
          }
        ],
        ethereumAddress: address,
        ethereumProvider: myWeb3Provider && myWeb3Provider.provider,
        theming: {
          backgroundColor: "#3A5560",
          textColor: 'white',
          panel: {
            textColor: '#3A5560',
            backgroundColor: 'white'
          }
        },
        settings: {
          slippage: 0.01,
          deadlineMinutes: 20,
          disableMultihops: false,
          uniswapVersions: [UniswapVersion.v2, UniswapVersion.v3],
        }
      };
      setUniswapDappSharedLogicContext(uniswapDappSharedLogicContext);
    })();
  }, [address, myWeb3Provider]);

  function clickTweet() {
    const uriText = encodeURIComponent(createTweetText(address))
    window.open(`https://twitter.com/intent/tweet?text=${uriText}`)
  }

  function initProvider() {

    function disconnectAll() {
      setWalletConnected(false)
      setAddress(null)
    }
    // Subscribe to accounts change
    provider.on("accountsChanged", (accounts) => {
      console.log(accounts);
      if (accounts.length === 0) {
        disconnectAll()
      } else {
        setAddress(provider.selectedAddress)
      }
    });

    // Subscribe to chainId change
    provider.on("chainChanged", (chainId) => {
      console.log(chainId);
    });

    // Subscribe to provider connection
    provider.on("connect", (info) => {
      console.log(info);
      setAddress(provider.selectedAddress)
      setWalletConnected(true)
    });

    // Subscribe to provider disconnection
    provider.on("disconnect", (error) => {
      console.log(error);
      disconnectAll()
    });
  }

  async function connectWeb3() {
    provider = await web3Modal.connect()
    const web3Provider = new providers.Web3Provider(provider)
    await web3Provider.provider.enable()
    let mySigner = web3Provider.getSigner()
    setMyWeb3Provider(web3Provider)
    myAddress = await mySigner.getAddress();
    initProvider()
    setAddress(myAddress)
    setWalletConnected(true)
  }

  async function importTokens() {
    window.myweb3 = myWeb3Provider.provider
    await myWeb3Provider.provider.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
        options: {
          address: CONTRACT_ADDRESS, // The address that the token is at.
          symbol: 'PUP', // A ticker symbol or shorthand, up to 5 chars.
          decimals: 3, // The number of decimals in the token
          image: PUPPY_LOGO_URL, // A string url of the token logo
        },
      },
    })
  }

  function BuyDirectly() {
    return (
      <p><a href={UNISWAP_TRADE_URL} target='_blank' alt="uniswap" rel="noreferrer" className='click-here'>Or buy directly on Uniswap</a></p>
    )
  }

  function ConnectWalletButton() {
    return (
      <>
        <h1 className="head-text">
          PUPPY COIN IS LIVE!
        </h1>
        <br />
        <div className="button" id="connect-button" onClick={connectWeb3}>
          Connect your Wallet to buy PUP
        </div>
        { BuyDirectly() }
        <br />
      </>
    )
  }

  function TweetSection() {
    return (
      <>
        <h1 className="head-text">
          Spread the Word
        </h1>
        <p>By tweeting about Puppy Coin you can help spread the fun!</p>
        <div className="button" id="tweet-button" onClick={clickTweet}>
          Click to generate tweet draft
        </div>
      </>
    )
  }

  function UniswapFlow() {
    return (
      <>
      <h1 className="head-text">
        SWAP ETH FOR PUP
      </h1>
        {uniswapDappSharedLogicContext !== undefined && (
          <UniswapReact
            uniswapDappSharedLogicContext={uniswapDappSharedLogicContext}
          />
        )}
      { BuyDirectly() }
      <p><span onClick={importTokens} className="click-here">Import Puppy Coins to your wallet</span></p>
    </>
    )
  }


  function MainButton() {
    if (!walletConnected) return ConnectWalletButton()
    //return BuyCoinsButton()
    return UniswapFlow()
  }


  function WalletInfo() {
    if (!walletConnected) {
      return (
        <>
        <div><br /></div><br/><br/>
        </>
      )
    }
    return (
      <>
        <br/><div>Connected to { ellipseAddress(address) }</div><br/>
      </>
    )
  }
  
  return (
    <div className="App">
      <div className="divider">
        <div id="walletInfo">
           { WalletInfo() }
        </div>
      </div>
      <div className="section paws">
        <div>
          <div id="top-pup-logo">
            🐶
          </div>
          <div id="main-container" className="blue-bordered">
            { MainButton() }
          </div>
        </div>
      </div>
      <div className="divider"><br /></div>
      <div className="section puppies" id="Info">
        <div className="info-section white-background">
          <div className="info-title">🐶 Origin Story 🐦</div>
            <p>
              On December 10, 2021, a mysterious programmer by the name of Puptoshi Barkamoto appeared on Twitter and released Puppy Coin to the world.
            </p>
            <p>
              The vision was simple: a truly decentralized collectible meme coin that nobody could control.
            </p>
            <p>
              In the beginning, anybody could mint new coins if they first tweeted out a message of support.
            </p>
            <p>
              Now, no more Puppy Coins can ever be created and it trades freely on the open market. Nobody has any control of the project. 
            </p>
        </div>
        <div className="info-section blue-background">
          <div className="info-title">🚀 Details 🌙</div>
            <p>
              Puppy Coin has a fixed supply of 21,696,969,696 PUP. 
            </p>
            <p> 
              All coins were created fairly in the initial minting and no new coins can ever be created.
            </p>
            <p>
              There is no team or group behind Puppy Coin. After the minting period, Puptoshi gave up all controls so that the project could run in a decentralized manner.
            </p>
            <p>
              Purchasing Puppy Coins is not an investment contract, and there should be no expectation of future profit. The future value of Puppy Coins is unknown because nobody controls it.
            </p>
        </div>
      </div>
      <div className="divider"><br /></div>
      <div className="section paws" id="SmartContract">
        <div className="info-section white-background blue-bordered">
          <div className="info-title">🤓 Smart Contract 📝</div>
            <p>Puptoshi has relinquished all admin controls to the smart contract, so it now runs on its own secured by the blockchain.
            </p>
            <p>
              The smart contract is verified and can be viewed on Etherscan below:
            </p>
            <p><a id="contract-address" href={`${ETHERSCAN_BASE_URL}/address/${CONTRACT_ADDRESS}#code`} alt="twitter-logo" rel="noreferrer" target="_blank">{CONTRACT_ADDRESS}</a></p>
            <p><span onClick={importTokens} className="click-here">Click here to make your Puppy Coins to show up in your wallet</span></p>
            <p>If that doesn't work, you can <a href={HOW_TO_IMPORT_URL} alt="manual-2" rel="noreferrer" target="_blank">manually import</a> the tokens with above the contract address, token symbol PUP, and 3 decimals</p>
        </div>
      </div>
      <div className="section colored" id="footer">
        <div className="full">
          {TweetSection()}
          <br /><br /><br />
        </div>
        <div id="footer-logos">
          <div className="footer-logo-container">
            <img id="twitter-logo" className="footer-image" src="twitter-icon.png" alt="twitter-logo-icon" onClick={() => window.open('https://twitter.com/i/lists/1474390602540142599')} />
          </div>
          <div className="footer-logo-container">
            <img id="instagram-logo" className="footer-image" src="instagram-icon.png" alt="instagram-logo-icon" onClick={() => window.open('https://instagram.com/puppy_coin')} />
          </div>
          <div className="footer-logo-container">
            <img id="discord-logo" className="footer-image" src="discord-icon.png" alt="discord-logo-icon" onClick={() => window.open('https://discord.gg/6uRBXwazq5')} />
          </div>
          <div className="footer-logo-container">
            <img id="telegram-logo" className="footer-image" src="telegram-icon.png" alt="telegram-logo-icon" onClick={() => window.open('https://t.me/puppycoinpup1')} />
          </div>
        </div>
        <div id="footer-internal">
          Join the wonderful community
        </div>
      </div>
    </div>
  );
}

export default App;
