import WalletConnectProvider from '@walletconnect/web3-provider';
import WalletLink from 'walletlink';

const INFURA_IDS = [
  '389f4357e69d4015a4b3ea9cc1d4dafa',  // 123
  '56fea18fdb1e451da3b5f1bb1db11c67', // 69
  'a363efee921a4e5fb87c834f87cefee0', // 691
  '175fd5e78c6b4c7ea3f5802e267b50a2', // 692
  '037b5dfefde14fcea085bd8fce78ac0d', // manin36319@zoeyy.com
  'b9fd820940aa4388b4b402a8d40ee48a', // welive2278@veb65.com
  '7f98f6da8a414c27a5f713386fe40645', // hobedas282@xxyxi.com
  '8f0d0450d8374625a2c7179722f544f8', // cowev68097@xxyxi.com
  '045d0c2e67364598925628fb4cf242b2', // gadic33452@xxyxi.com
  '513403e13f45439e9915bfb7a4fd4c2d', // repeha1518@zherben.com
  '9b7a70674fa34a3ebed3ad21509dd232', // fayake9610@saturdata.com
]

const INFURA_ID = chooseRandom(INFURA_IDS)

function chooseRandom(list) {
  const index = Math.floor(list.length * Math.random())
  return list[index]
}

export const providerOptions = {
    // Example with injected providers
    injected: {
      display: {
        // logo: "data:image/gif;base64,INSERT_BASE64_STRING",
        name: 'BROWSER',
        description: 'CONNECT WITH THE PROVIDER IN YOUR BROWSER',
      },
      package: null,
    },
    // Example with WalletConnect provider
    walletconnect: {
      display: {
        // logo: "data:image/gif;base64,INSERT_BASE64_STRING",
        name: 'MOBILE',
        description: 'MOBILE WALLET CONNECT',
      },
      package: WalletConnectProvider,
      options: {
        infuraId: INFURA_ID,
      }
    },
    'custom-walletlink': {
      display: {
        logo: 'https://play-lh.googleusercontent.com/PjoJoG27miSglVBXoXrxBSLveV6e3EeBPpNY55aiUUBM9Q1RCETKCOqdOkX2ZydqVf0',
        name: 'COINBASE',
        description: 'CONNECT TO COINBASE WALLET (NOT COINBASE APP)',
      },
      options: {
        appName: 'Coinbase', // Your app name
        networkUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
        chainId: 1,
      },
      package: WalletLink,
      connector: async (_, options) => {
        const { appName, networkUrl, chainId } = options;
        const walletLink = new WalletLink({
          appName,
        });
        const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
        await provider.enable();
        return provider;
      },
    },
  };